import React, { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../utils';
import NewBanner from '../../assets/images/new-landing-page/photo.jpg';
import './new-landing-page.css';

export const NewLandingPage = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  const deviceInfo = {
    operatingSystem: detectOperatingSystem(),
    deviceType: detectDeviceType(),
    browser: detectBrowser(),
  };

  function detectOperatingSystem() {
    if (userAgent.includes('windows')) return 'Windows';
    if (userAgent.includes('mac os')) return 'Mac OS';
    if (userAgent.includes('android')) return 'Android';
    if (userAgent.includes('iphone') || userAgent.includes('ipad'))
      return 'iOS';
    if (userAgent.includes('linux')) return 'Linux';
    return 'Unknown OS';
  }

  function detectDeviceType() {
    if (userAgent.includes('mobile')) return 'Mobile';
    if (userAgent.includes('tablet')) return 'Tablet';
    return 'Desktop';
  }

  function detectBrowser() {
    if (userAgent.includes('chrome')) return 'Chrome';
    if (userAgent.includes('safari') && !userAgent.includes('chrome'))
      return 'Safari';
    if (userAgent.includes('firefox')) return 'Firefox';
    if (userAgent.includes('edge')) return 'Edge';
    return 'Unknown Browser';
  }

  useEffect(() => {
    logEvent(
      analytics,
      `new_landing_page_visited_by_${deviceInfo.deviceType}`,
      {
        ...deviceInfo,
      },
    );
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '24px',
      }}>
      <img
        src={NewBanner}
        alt={''}
        style={{
          width: '100%',
          maxWidth: '600px',
          borderRadius: '8px',
          maxHeight: '80vh',
          overflow: 'hidden',
        }}
      />
      <h4 style={{ textTransform: 'capitalize' }} className="textStyle">
        I wish!
      </h4>
      <audio controls loop autoPlay style={{ marginTop: '20px' }}>
        <source
          src={`${process.env.PUBLIC_URL}/song2.m4a`}
          type="audio/mp3"></source>
      </audio>
    </div>
  );
};
